<template>
  <div class="uploadCheckImage">
    <div class="uploadCheckImage__header">
      <el-checkbox disabled v-model="backChecked" />
      <p>
        {{ title }}
      </p>
      <span v-if="backChecked" @click="handlePreview('back')">
        {{ reselectButtonText }}
      </span>
    </div>
    <div v-show="!backChecked">
      <slot />
    </div>
    <div v-show="!backChecked" class="uploadCheckImage__footer">
      <el-button type="primary" class="width-100 el-button__brand brand" @click="handlePreview()">
        {{ uploadButtonText }}
      </el-button>
      <input
        accept="image/*,application/pdf"
        class="hidden-file"
        @change="fileOnChange($event)"
        type="file"
        ref="fileInput">
    </div>
    <div v-if="backChecked" class="uploadCheckImage__image">
      <img v-if="file.type.startsWith('image/')" :src="url" alt="image">
      <embed
        v-else-if="file.type === 'application/pdf'"
        :src="url"
        width="300px"
        height="190px">
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    value: File
  },
  data() {
    return {
      backChecked: false,
      url: '',
      file: null
    };
  },
  created() {
    if (this.value) {
      this.file = this.value;
      this.url = URL.createObjectURL(this.value);
      this.backChecked = true;
    }
  },
  methods: {
    handlePreview() {
      this.$refs.fileInput.click();
    },
    fileOnChange(e) {
      const [file] = e.target.files;
      if (file) {
        this.url = URL.createObjectURL(file);
        this.file = file;
        this.backChecked = true;
        this.$emit('fileChange', file);
      }
    }
  },
  computed:{
    uploadButtonText(){
      return this.$attrs['upload-btn-text'] ?? this.$t('RCD_upload_photo');
    },
    reselectButtonText(){
      return this.$attrs['reselect-btn-text'] ?? this.$t('RCD_reuploadButton');
    },
  }
};
</script>
<style lang="scss">
.uploadCheckImage {
  .hidden-file{
    display: none;
  }
  border-radius: var(--radius);
  background-color: #fff;
  padding: 16px 24px;
  margin-bottom: 16px;
  border: 1px solid #EDEFF1;
  &__image {
    overflow: hidden;
    height: 200px;
    img {
      width: 100%;
    }
  }

  &__header {
    display: flex;
    padding-bottom: 13px;
    align-items: center;

    .el-checkbox__inner {
      width: 20px;
      height: 20px;

      &:after {
        top: 5px;
        left: 7px;
      }
    }

    p {
      padding-left: 16px;
      font-family: var(--branding);
    }

    span {
      margin-left: auto;
      text-decoration: underline;
      color: #90909e;
      cursor: pointer;
      font-size: 14px;
    }
  }

  ul {
    padding-bottom: 16px;

    li {
      color: #90909e;
      font-size: 14px;
      line-height: 20px;
    }
  }
}
</style>